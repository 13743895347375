import {navigate} from 'gatsby'
import React, {useEffect} from 'react'

import useViewer from 'src/hooks/useViewer'
import {AddUserRelativResponse, addUserRelativ} from 'src/lib/util'

const ResumeBuilder = () => {
  const {viewer} = useViewer()

  useEffect(() => {
    if (viewer === 'not-logged-in') {
      navigate('/log-in/?redirect_from=resumebuilder', {
        state: {returnTo: '/resumebuilder'},
      })
    } else {
      const email = viewer?.student.email
      const name = viewer?.student.name

      if (email) {
        addUserRelativ(name, email)
          .then((response: AddUserRelativResponse) => {
            if (response.status === 'success') {
              console.log('Authentication successful')
              const url = new URL('https://resumebuilder.getschooled.com/')
              url.searchParams.append('email', response.email || '')
              navigate(url.toString())
            } else if (response.status === 'fail') {
              console.log('Authentication failed')
            } else {
              console.error('Unknown status:', response.status)
            }
          })
          .catch((error) => {
            console.error('Error:', error)
          })
      }
    }
  }, [viewer])

  return <></>
}

export default ResumeBuilder
